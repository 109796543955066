import { Link } from '@mui/material'
import { Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ApplyLink from '../components/ApplyLink'
import { application, instagramLink } from '../data/info'
import applyImage from '../resources/apply.png'

const Apply = () => {
  return (
    <Stack
      direction={{ md: 'row', xs: 'column' }}
      alignItems="center"
      sx={{
        height: { md: '440px', xs: 'inherit' },
        backgroundColor: '#F2F2F2',
        overflowY: 'visible',
      }}
      mt={{ md: 7, xs: 0 }}
      mb={{ md: 7, xs: 6 }}
    >
      <Stack
        alignItems={{ md: 'flex-start', xs: 'center' }}
        sx={{ width: { md: '48%', xs: '100%' } }}
        pl={{ md: '16.5%', xs: 3 }}
        pr={{ md: 0, xs: 3 }}
        pt={{ md: 0, xs: 5.5 }}
        mr={{ md: 4, xs: 0 }}
      >
        <Typography variant="sectionTitle2">Como participar?</Typography>
        <Typography variant="sectionText" pt={1.5}>
          {' '}
          {application.open ? (
            application.applicationsOpen ? (
              <>
                A próxima turma está com <b>inscrições para processo seletivo aberto até {application.endDate}</b>.
              </>
            ) : (
              'Abrimos uma nova turma a cada 2 anos. As inscrições para o processo seletivo da turma 2025/26 estão encerradas.'
            )
          ) : (
            <>
              As inscrições para o{' '}
              <Link color="interaction" variant="link" sx={{ display: 'inline' }} href={'./processo'}>
                processo seletivo da turma 2025/26
              </Link>{' '}
              estão encerradas. Abrimos uma nova turma a cada 2 anos. Até lá, nos acompanhe pelas redes sociais!
            </>
          )}
        </Typography>
        <Typography variant="sectionText" pt={1} pb={{ xs: 2, md: 4 }}>
          {application.open &&
            application.applicationsOpen &&
            'Se você tem paixão por aprender, você tem o perfil da Academy. Inscreva-se!'}
        </Typography>
        {application.open && <ApplyLink>Acompanhe o Processo Seletivo</ApplyLink>}
        {!application.open && <ApplyLink href={instagramLink}>Instagram da Academy</ApplyLink>}
      </Stack>
      <Box
        component="img"
        src={applyImage}
        alt={applyImage}
        sx={{
          width: { md: '50%', xs: '108%' },
          height: { md: 'auto', xs: 'auto' },
          marginLeft: { md: 0, xs: '-8%' },
          marginBottom: { md: 4, xs: -6 },
        }}
      />
    </Stack>
  )
}

export default Apply
