import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import Main from './ui/0-Main'
import theme from './theme'
import { useEffect } from 'react'
import popupImage from './resources/popup.jpg'
import { application } from './data/info'

function App() {
  useEffect(() => {
    if (application.applicationsOpen) {
      document.getElementById('show_modal').showModal()
    }
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Main />
      </ThemeProvider>
      <dialog id="show_modal" className="modal">
        <div className="modal-box p-0 max-h-[80vh] max-w-[66.5vh] m-5">
          <a href="./processo">
            <img src={popupImage} className="" alt="popUpImage"></img>
          </a>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  )
}

export default App
