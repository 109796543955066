import React from 'react'
import { AppBar, Container, Toolbar, Box, IconButton, Menu, Link } from '@mui/material'
import logo from '../resources/logo.jpg'
import { Menu as MenuIcon } from '@mui/icons-material'
import ApplyLink from '../components/ApplyLink'
import scrollToElement from '../util/scrollToElement'
import { application } from '../data/info'

const pages = ['Home', 'Sobre', 'Apps', 'Mentoria', 'Contato']

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const pageLinks = (
    <Box>
      {pages.map((page) => (
        <Link
          key={page}
          variant="navlink"
          color="#000"
          underline="none"
          onClick={() => {
            handleCloseNavMenu()
            scrollToElement(page)
          }}
          sx={{ display: { md: 'inline', xs: 'block' }, textAlign: 'start' }}
          py={{ md: 0, xs: 1 }}
          px={{ md: 0, xs: 3 }}
          mx={{ md: 3, xs: 0 }}
        >
          {page}
        </Link>
      ))}
    </Box>
  )

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: 'white',
        paddingY: 2,
        boxShadow: '0 5px 15px 0 rgba(159, 5, 56, 0.2)',
      }}
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, justifyContent: { xs: 'flex-start', md: 'center' } }}>
            <Box
              component="img"
              src={logo}
              alt={logo}
              sx={{
                width: { md: 200, xs: 150 },
                height: 'auto',
                justifyContent: { xs: 'flex-start', md: 'center' },
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: 'black' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pageLinks}
            </Menu>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'flex-end',
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {pageLinks}
          </Box>
          {application.open && (
            <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
              <ApplyLink
                isNav={true}
                href={'./processo'}
                onClick={() => {
                  handleCloseNavMenu()
                  scrollToElement('Processo')
                }}
                sx={{
                  width: { md: 'inherit', xs: '90vw' },
                  textAlign: 'start',
                  paddingY: { md: 0, xs: 1 },
                  paddingX: { md: 0, xs: 3 },
                  marginX: { md: 3, xs: 3 },
                }}
              >
                Processo Seletivo
              </ApplyLink>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default ResponsiveAppBar
